import styled from 'styled-components';


import Hero2800 from '../../images/home-hero-2800.jpg';
import Hero1920 from '../../images/home-hero-1920.jpg';
import HeroImage from '../../images/heroImage.png';
import HeroMobile from '../../images/hero-mobile.jpg';

import {
  TABLET_992, TABLET_768, DESKTOP_1024, MOBILE_460, DESKTOP_1366, DESKTOP_1920, MOBILE_380,
} from '../../styles/globals/sizes';

import {
  RED_TITLE, WHITE, BLACK, BLACK_GET_IN_TOUCH, GRAY_HOVER, BLACK_TEXT, RED_NEW_TITLE,
  GRAY_CARD, BLACK_NEW_TEXT, GRAY_UNDER_TITLE, WHITE_CARDS
} from '../globals/colors';

export const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeroContainer = styled.div`
  background-position-y: bottom;
  background-image: url(${Hero2800});
  height: 507px;
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${DESKTOP_1920}px) {
    background-image: url(${Hero1920});
  } 

  @media (max-width: ${DESKTOP_1366}px) {
    background-image: url(${HeroImage});
    background-position-x: right;
  }

  @media (max-width: ${TABLET_992}px) {
    background-image: url(${HeroMobile});
    height: 780px;
    background-position-x: center;
    display: flex;
    justify-content: flex-start;
  }

  @media (max-width: ${MOBILE_460}px) {
    height: 520px;
    margin-top: 30px;
    background-position-x: left;
  }
`;

export const TextDiv = styled.div`

  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 100%;
  }

  @media (max-width: ${TABLET_768}px) {
    margin-bottom: 120px;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-bottom: 20px;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${TABLET_992}px) {
    margin-right: 0;
  }
`;

export const Title = styled.h1`
  color: ${RED_NEW_TITLE};
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  margin-top: 80px !important;

  @media (max-width: ${TABLET_992}px) {
    text-align: center;
    margin-top: 100px !important;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px !important;
  }

`;

export const IntroductionDiv = styled.div`
  color: ${GRAY_UNDER_TITLE};
  margin-top: 24px;
  width: 41%;
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin-top: 14px;
  }

  @media (max-width: ${MOBILE_460}px) {
    line-height: 26px;
    font-weight: 300;
    font-size: 19px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin-bottom: 44px;
`;

export const CustomButton = styled.button`
  margin-top: 23px;
  background-color: ${RED_TITLE};
  border-radius: 25px;
  opacity: 1;
  padding: 14px 40px;
  border: 0;
  color: ${WHITE};
  transition: 1s;
  font-size: 16px;
  font-weight: 600;

  :hover {
    background-color: ${GRAY_HOVER};
    color: ${WHITE};
  }
`;

export const LearnMoreButton = styled.button`
  background-color: ${RED_TITLE};
  border-radius: 25px;
  opacity: 1;
  padding: 14px 40px;
  border: 0;
  color: ${WHITE};
  width: fit-content;
  align-self: center;
  transition: 1s;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin: 53px 0 0 0;

  :hover {
    background-color: ${GRAY_HOVER};
  }

  @media (max-width: ${TABLET_992}px) {
    margin: 31px 0px 0 0;
  }
`;

export const MakeUsDifferentSection = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: row;
  
  @media (max-width: ${TABLET_992}px) {
    text-align: center;
    margin-top: 51px;
  }
`;

export const InfoText = styled.div`
  padding-right: 36px;

  @media (max-width: ${TABLET_992}px) {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Text = styled.div`
  margin-top: 22px;
  font-size: 16px;
  line-height: 29px;
  color: ${BLACK_TEXT};
  font-weight: 300;
`;

export const ImageDiv = styled.img`
  height: 232px;
  width: 100%;

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const SubTitle = styled.h2`
  font-size: 35px;
  line-height: 42px;
  margin-top: 19px;
  font-weight: 600;
  color: ${RED_TITLE};

  @media (max-width: ${TABLET_992}px) {
    text-align: center;
  }
`;

export const UnderLineTitle = styled.div`
  width: 113px;
  height: 3px;
  margin-top: 10px;
  background-color: ${({ isWhite }) => (isWhite ? WHITE : RED_TITLE)};

  @media (max-width: ${TABLET_992}px) {
   
  }
`;

export const ServicesTitle = styled.h2`
  font-size: 35px;
  line-height: 42px;
  color: ${RED_TITLE};
  align-self: center;
  font-weight: 600;
  margin-bottom: 0px;

  @media (max-width: ${TABLET_992}px) {
      margin-right: 0px;
  }
`;

export const ServicesSection = styled.div`
  margin-top: 82px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${TABLET_992}px) {
    margin-top: 50px;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 62px;

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const TheraphyDiv = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  width: 33%;
`;

export const PhysicalLogo = styled.img`
  width: 62px;
  height: 60px;

  @media (max-width: ${MOBILE_380}px) {
    display: none !important;
  }
`;

export const OccupationalLogo = styled.img`
  width: 122px;
  height: 115px;
  
  @media (max-width: ${MOBILE_380}px) {
    display: none !important;
  }
`;

export const SpeechLogo = styled.img`
  width: 131px;
  height: 115px;

  @media (max-width: ${MOBILE_380}px) {
    display: none !important;
  }
`;

export const TheraphyTitle = styled.div`
  font-size: 25px;
  line-height: 30px;
  color: ${RED_TITLE};
  font-weight: 600;
  text-align: center;
  padding-left: 12px;
  text-align: start;

  @media (max-width: ${MOBILE_380}px) {
    padding-left: 0;
  }
`;

export const TheraphyText = styled.div`
  font-size: 16px;
  line-height: 29px;
  color: ${BLACK_GET_IN_TOUCH};
  width: 85%;
  font-weight: 300;
  text-align: -webkit-center;
`;

export const SpeciallyForYouSection = styled.div`
  display: flex;
  flex-direction: row;
  background: ${GRAY_CARD};
  margin-top: 68px;

  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
    height: auto;
  }
`;

export const MakesUsDifferentSection = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent linear-gradient(91deg, #EC3E2C 0%, #ED67A6 100%) 0% 0% no-repeat padding-box;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    height: auto;
  }
`;

export const SpeciallyForYouImageMobile = styled.img`
  display: none;

  @media (max-width: ${MOBILE_460}px) {
    display: flex;
  }
`;

export const MakesUsDifferentImage = styled.img`
  display: none;

  @media (max-width: ${DESKTOP_1366}px) {
    width: 50%;
    display: flex;
  }

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const SpeciallyForYouImageBottom = styled.img`
  display: none;

  @media (max-width: ${DESKTOP_1366}px) {
    width: 50%;
    display: flex;
  }

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const MakesUsDifferentImage1920 = styled.img`
  display: none;

  @media (max-width: ${DESKTOP_1920}px) {
    width: 50%;
    display: flex;
  }

  @media (max-width: ${DESKTOP_1366}px) {
    display: none;
  }
`;

export const MakesUsDifferentImage2800 = styled.img`
  width: 50%;
  
  @media (max-width: ${DESKTOP_1920}px) {
    display: none;
  }
`;

export const SpeciallyForYouText = styled.div`
  width: 50%;
  display: flex;
  align-items: ${({ hasAlignCenter }) => (hasAlignCenter ? 'center' : 'start')};
	background: ${GRAY_CARD};
  justify-content: center;

  @media (max-width: 1125px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    padding: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 25px;
  }

  @media (max-width: ${MOBILE_460}px) {
    padding-bottom: 40px;
  }
`;

export const WhatMakesUsDifferent = styled.div`
  width: 50%;
  display: flex;
  align-items: ${({ hasAlignCenter }) => (hasAlignCenter ? 'center' : 'start')};
	background: transparent linear-gradient(91deg, #EC3E2C 0%, #ED67A6 100%) 0% 0% no-repeat padding-box;
  justify-content: center;

  @media (max-width: 1125px) {
    padding: 0 15px;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    padding: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 15px;
  }
`;

export const ForYouTitle = styled.h2`
  margin-top: 70px !important;
  font-size: 31px;
  line-height: 40px;
  font-weight: 600;
  color: ${RED_TITLE};

  @media (max-width: 1124px) {
    width: 100%;
    margin-top: 0 !important;
  }
`;

export const MakesUsDifferentTitle = styled.h2`
  font-size: 31px;
  line-height: 40px;
  font-weight: 600;
  color: ${WHITE};

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }
`;

export const ForYouTextDiv = styled.div`
  
  @media (max-width: 1124px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const ForYouText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color:${({ isWhite }) => (isWhite ? WHITE : BLACK)};
  display: flex;
  margin-top: 26px;
  font-weight: 300;
`;

export const BoldText = styled.div`
  display: contents;
  font-weight: 500;
`;

export const ForYouWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin-left: 0;
  
  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 90%;
  }

  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
  }
`;

export const SliderContainer = styled.div`
  display: none;

  @media (max-width: ${TABLET_992}px) {
    display: block;
    margin-top: 40px;
  }
`;

export const DivButtonMobile = styled.div`
  display: ${({ notDisplayInMobile }) => (notDisplayInMobile ? 'flex' : 'none')};
  justify-content: center;

  @media (max-width: ${TABLET_992}px) {
    margin-top: 36px;
    display: ${({ notDisplayInMobile }) => (notDisplayInMobile ? 'none' : 'flex')};
  }
`;

export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  color: ${GRAY_CARD};
  border-radius: 20px;
  box-shadow: 3px 3px 6px #00000029;
  width: 31%;
  transition: transform .2s;
  background-color: ${WHITE_CARDS};

  :hover {
    transform: scale(1.1); 
  }

  @media (max-width: ${TABLET_992}px) {
    width: 340px !important;
    box-shadow: none;
    
    :hover {
      transform: scale(1); 
    }
  }

  @media (max-width: ${MOBILE_380}px) {
    width: 240px !important;
  }
`;

export const CardContent = styled.div`
  padding: 30px 20px 51px 20px;

  @media (max-width: ${MOBILE_380}px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const LogoAndTitle = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${MOBILE_380}px) {
    text-align-last: center;
  }
`;

export const CardImage = styled.img`

  @media (max-width: ${MOBILE_380}px) {
    width: 240px;
  }
`;

export const CardText = styled.div`
  color: ${BLACK_NEW_TEXT};
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const SpeciallyForYouImage1920 = styled.img`
  display: none;
  
  @media (max-width: ${DESKTOP_1920}px) {
    width: 50%;
    display: flex;
  }

  @media (max-width: ${DESKTOP_1366}px) {
    display: none;
  }
`;

export const SpeciallyForYouImage2800 = styled.img`
  width: 50%;

  @media (max-width: ${DESKTOP_1920}px) {
    display: none;
  }
`;

export const SpeciallyForYouImageTablet = styled.img`
  display: none;

  @media (max-width: ${TABLET_768}px) {
    display: flex;
  }

  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`;