import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seoinfo from "../components/seoinfo/seoinfo"
import CustomSlider from "../components/slider"
import { PATHS } from "../config/urls"
import { Link } from "gatsby"

import Physical from "../images/physical.svg"
import Speech from "../images/speech.svg"
import Occupational from "../images/occupation.svg"

import SpeechCard from "../images/speechCard.png"
import TheraphyCard from "../images/theraphyCard.png"
import OccupationalCard from "../images/ocuppationalCard.png"

import whatMakesUsDifferent from "../images/whatMakesUsDifferent.png"
import whatMakesUsDifferent1920 from "../images/What-makes-us-different1920.png"
import whatMakesUsDifferent2800 from "../images/What-makes-us-different-2800.png"

import EspeciallyForYou from "../images/EspeciallyForYou.png"
import EspeciallyForYouMobile from "../images/especiallyForYouMobile.png"
import forYou1920 from "../images/forYou1920.png"
import forYou2800 from "../images/forYou2800.png"

import GetInTouchSection from "../components/getInTouchSection/getInTouchSection"
import AOS from "aos"

import { InternalWrapper } from "../styles/globals/styles.jsx"

import {
  HeroContainer,
  TextDiv,
  Title,
  IntroductionDiv,
  CustomButton,
  ServicesSection,
  PhysicalLogo,
  ServicesTitle,
  TheraphyTitle,
  LearnMoreButton,
  SpeciallyForYouSection,
  MakesUsDifferentImage,
  SpeciallyForYouText,
  ForYouTitle,
  ForYouText,
  UnderLineTitle,
  TitleDiv,
  BoldText,
  ForYouWrapper,
  ForYouTextDiv,
  SliderContainer,
  DivButtonMobile,
  MakesUsDifferentImage1920,
  MakesUsDifferentImage2800,
  ServiceCard,
  CardContent,
  CardImage,
  CardText,
  LogoAndTitle,
  CardsContainer,
  MakesUsDifferentTitle,
  MakesUsDifferentSection,
  SpeciallyForYouImageBottom,
  WhatMakesUsDifferent,
  SpeciallyForYouImageMobile,
  IntroductionAndButton,
  ButtonDiv,
  SpeciallyForYouImage1920,
  SpeciallyForYouImage2800,
  SpeciallyForYouImageTablet,
} from "../styles/pages/home.jsx"

const Services = [
  <ServiceCard>
    <CardImage src={TheraphyCard} alt="Physical therapy to patient"/>
    <CardContent>
      <LogoAndTitle>
        <PhysicalLogo src={Physical} />
        <TheraphyTitle>
          PHYSICAL <br /> THERAPY
        </TheraphyTitle>
      </LogoAndTitle>
      <CardText>
        Getting around comfortably and freely is the goal of physical therapy.
      </CardText>
    </CardContent>
  </ServiceCard>,
  <ServiceCard>
    <CardImage
      src={OccupationalCard}
      alt="Nurse performing recovery therapy to patient"
    />
    <CardContent>
      <LogoAndTitle>
        <PhysicalLogo src={Occupational} />
        <TheraphyTitle>
          OCCUPATIONAL <br /> THERAPY
        </TheraphyTitle>
      </LogoAndTitle>
      <CardText>
        Improving our patient's activities of daily living is the objective of
        occupational therapy.
      </CardText>
    </CardContent>
  </ServiceCard>,
  <ServiceCard>
    <CardImage src={SpeechCard} alt="Nurse performing speech therapy to patient" />
    <CardContent>
      <LogoAndTitle>
        <PhysicalLogo src={Speech} />
        <TheraphyTitle>
          SPEECH <br /> THERAPY
        </TheraphyTitle>
      </LogoAndTitle>
      <CardText>
        Communication is the key focus of our speech-language pathology
        professionals.
      </CardText>
    </CardContent>
  </ServiceCard>,
]
const renderServices = () => {
  return Services.map(child => {
    return child
  })
}
const Home = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      })
    },
    [],
    AOS
  )

  return (
    <Layout hasTransparentTopBar>
      <Seoinfo
        siteName={"Life Home Therapy"}
        titlePage={"Life Home Therapy"}
        description={
          "Life Home Therapy provides an integral service where professionals focus on rehabilitating lives by providing the assistance and support you need."
        }
        keywords={
          "therapy services, in-home services, in-home assistance, physical therapy, occupational therapy, speech-language pathology, home therapy, home therapy services"
        }
        type={"website"}
        url={"hometherapy.life"}
        imageFacebook={"/seo/home.png"}
      />
      <HeroContainer alt="Therapist holding the arms of a man doing rope stretches">
          <InternalWrapper data-aos="fade">
          <TextDiv>
            <Title>
              LIVE THE LIFE <br />
              YOU WANT
            </Title>
            <IntroductionDiv>
              We provide exceptional in-home <br />
              therapy to motivate you physically
              <br /> and emotionally again.
            </IntroductionDiv>
            <ButtonDiv>
              <Link to={PATHS.CONTACT}>
                <CustomButton>Let's Meet</CustomButton>
              </Link>
            </ButtonDiv>
          </TextDiv>
        </InternalWrapper> 
      </HeroContainer>
      <MakesUsDifferentSection>
        <MakesUsDifferentImage
          src={whatMakesUsDifferent}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <MakesUsDifferentImage1920
          src={whatMakesUsDifferent1920}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <MakesUsDifferentImage2800
          src={whatMakesUsDifferent2800}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <WhatMakesUsDifferent hasAlignCenter>
          <ForYouWrapper data-aos="fade">
            <ForYouTextDiv>
              <MakesUsDifferentTitle>
                WHAT MAKES US DIFFERENT
              </MakesUsDifferentTitle>
              <UnderLineTitle isWhite />
              <ForYouText isWhite>
                At Life Home Therapy, we provide an integral service where our
                dedicated professionals are ready to give you their all. We
                rehabilitate lives by providing the assistance and support you
                need. Combined with our Life Home Care, Durable Medical
                Equipment, and Life Ride Transportation Services, we are
                constantly seeking ways to elevate your quality of life.
              </ForYouText>
            </ForYouTextDiv>
          </ForYouWrapper>
        </WhatMakesUsDifferent>
      </MakesUsDifferentSection>
      <div className="container">
        <ServicesSection>
          <TitleDiv>
            <ServicesTitle>SERVICES</ServicesTitle>
            <UnderLineTitle />
          </TitleDiv>
          <CardsContainer>{renderServices()}</CardsContainer>
          <DivButtonMobile notDisplayInMobile>
            <Link to={PATHS.SERVICES}>
              <LearnMoreButton>Learn More</LearnMoreButton>
            </Link>
          </DivButtonMobile>
        </ServicesSection>
        <SliderContainer>
          <CustomSlider childs={Services} />
        </SliderContainer>
        <DivButtonMobile>
          <Link to={PATHS.SERVICES}>
            <LearnMoreButton>Learn More</LearnMoreButton>
          </Link>
        </DivButtonMobile>
      </div>
      <SpeciallyForYouSection>
        <SpeciallyForYouImageTablet
          src={EspeciallyForYou}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <SpeciallyForYouImageMobile
          src={EspeciallyForYouMobile}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <SpeciallyForYouText>
          <ForYouWrapper data-aos="fade">
            <ForYouTextDiv>
              <ForYouTitle>
                PLANNED ESPECIALLY <br /> FOR YOU
              </ForYouTitle>
              <UnderLineTitle />
              <ForYouText>
                Recovering from an injury, illness or surgery isn’t easy. It
                can sometimes mean learning how to do simple things and daily
                tasks again.{" "}
                <BoldText>
                  {" "}
                  Our physical, occupational, and speech therapists{" "}
                </BoldText>{" "}
                are prepared to help you regain your lifestyle as it was,
                planning everything to fit your specific needs and
                possibilities. All the process happens in a familiar
                surrounding.
              </ForYouText>
            </ForYouTextDiv>
          </ForYouWrapper>
        </SpeciallyForYouText>
        <SpeciallyForYouImageBottom
          src={EspeciallyForYou}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <SpeciallyForYouImage1920
          src={forYou1920}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
        <SpeciallyForYouImage2800
          src={forYou2800}
          alt="Therapist encouraging an elderly woman to perform arm stretching exercises"
        />
      </SpeciallyForYouSection>
      <GetInTouchSection />
    </Layout>
  )
}

export default Home
