import React, {useEffect} from "react";
import { Link } from "gatsby"
import AOS from 'aos';
import { PATHS } from '../../config/urls';

import {
  GetInTouchContainer, GetInTouchext, BoldText, GetInTouchButton, GetInTouchDiv
} from './styles.jsx';

const GetInTouchSection = ({maxWidth}) => {

  useEffect(() => {
    AOS.init({
     
      duration : 2000,
      once: true
    });
  }, [], AOS);

  return(
    <GetInTouchContainer>
     <div data-aos="fade" className="container">
       <GetInTouchDiv maxWidth={maxWidth}>
        <GetInTouchext>
          FIND OUT MORE ABOUT<BoldText> OUR SERVICES</BoldText>
        </GetInTouchext>
        <Link to={PATHS.CONTACT}>
          <GetInTouchButton>
            Get In Touch!
          </GetInTouchButton>
        </Link>
        </GetInTouchDiv>
      </div>
    </GetInTouchContainer>
  )
}

export default GetInTouchSection;