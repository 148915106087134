import styled from 'styled-components';

import getInTouch from '../../images/getInTouch.png';
import getInTouch768 from '../../images/Getintouch-768.png';
import getInTouchMobile from '../../images/Getintouch-mobile.png';

import {
  TABLET_992, TABLET_768, MOBILE_460
} from '../../styles/globals/sizes';

import {
  RED_TITLE, WHITE, GRAY_HOVER
} from '../../styles/globals/colors';


export const GetInTouchContainer = styled.div`
  background-image: url(${getInTouch});
  display: flex;
  flex-direction: row;
  padding: 70px 0;
  background-size: cover;
  align-items: center;
  flex: 1;
  justify-content: center;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
    background-image: url(${getInTouch768});
    padding: 40px 0;
  }

  @media (max-width: ${MOBILE_460}px) {
    background-image: url(${getInTouchMobile});
  }
`;

export const GetInTouchext = styled.div`
  font-size: 30px;
  line-height: 36px;
  color: ${WHITE};
  font-weight: 300;
  
  @media (max-width: ${TABLET_768}px) {
    text-align: center;
    font-size: 20px;
  }
`;

export const GetInTouchDiv = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${(props) => props.maxWidth ? props.maxWidth : 'none'};
  align-items: center;
  justify-content: center;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GetInTouchButton = styled.button`
  color: ${WHITE};
  border-radius: 25px;
  opacity: 1;
  padding: 14px 40px;
  border: 0;
  color: ${RED_TITLE};
  transition: 1s;
  font-size: 16px;
  font-weight: 600;
  margin-left: 60px;
  
  :hover {
    background-color: ${GRAY_HOVER};
    color: ${WHITE};
  }

  @media (max-width: ${TABLET_992}px) {
    width: fit-content;
    margin-left: 0;
    margin-top: 19px;
  }
`;

export const BoldText = styled.div`
  font-weight: 600;
  display: contents;
`;