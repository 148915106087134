import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Container } from './styles';

const CustomSlider = ({childs}) => {
   
  const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
    };
    return (
        <Container>
          <Slider {...settings}>
            {childs.map((child) => {
              return(   
                child
              )
            })}
          </Slider>
       </Container>

    );
  }

  export default CustomSlider;
